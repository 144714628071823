@mixin heading-1-bold {
  text-transform: uppercase;
  font-family: $font-heading;
  font-weight: 900;
  font-size: 66px;
  line-height: 66px; }

@mixin heading-1-normal {
  font-family: $font-heading;
  font-weight: 700;
  font-size: 66px;
  line-height: 66px; }

@mixin heading-2-bold {
  font-family: $font-heading;
  font-weight: 900;
  font-size: 36px;
  line-height: 36px; }

@mixin heading-2-normal {
  font-family: $font-heading;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px; }

@mixin heading-3 {
  font-size: 20px;
  font-weight: normal;
  line-height: 22px; }

@mixin paragraph {
  // font-size: 18px
 }  // line-height: 20px
