html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  font-family: $font-default;
  -webkit-font-smoothing: antialiased;
  color: white;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 28px; }

a {
  text-decoration: none;
  color: inherit; }

h1, h2, h3, h4, h5, h6, p, blockquote, dl, dd {
  margin: 0; }

img {
  max-width: 100%;
  height: auto; }

::selection {
  color: white;
  background: $blue; }

.button {
  display: inline-block;
  min-width: 140px;
  //height: 34px
  padding: 5px 15px;
  font-size: 14px;
  line-height: 26px;
  -webkit-font-smoothing: initial;
  color: white;
  cursor: pointer;
  user-select: none;
  background-color: $blue;
  border: 2px solid $blue;
  border-radius: 0;
  outline: none;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: -0.4375px;

  &:focus,
  &:hover {
    background-color: $white;
    color: $blue; }

  &:disabled {
    pointer-events: none;
    cursor: default;
    opacity: .5; }

  &--working {
    font-size: 0;
    pointer-events: none;
    cursor: default;

    &::after {
      margin-top: 1px;
      display: inline-block;
      font-family: FontAwesome;
      font-size: 18px;
      content: '\f013';
      animation: spin 2s linear infinite; } }

  &--secondary {
    color: $blue;
    background-color: white;

    &:hover {
      background-color: white;
      box-shadow: 0 3px 5px rgba(0,0,0,0.125); } } }

.block--color-blue {
  .button {
    background-color: $white;
    color: $blue; } }

.hide {
  display: none; }

@keyframes spin {

  100% {
    transform: rotate(360deg); } }


.event-password {
  max-width: 330px;
  margin: 30px auto;
  text-align: center;
  color: $black;

  .event-password-logo {
    display: block;
    margin: 0 auto 25px auto; }

  .alert {
    padding: 9px 0 4px 0;
    margin-bottom: 10px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1; }

  .form-row {
    margin-top: 10px;

    input[type="text"],
    input[type="password"] {
      border: 1px solid $black; } } }

table {
  text-align: left;
  border-collapse: collapse;

  th, td {
    padding: 5px 5px 2px 5px; }

  &.class1 {
    width: auto !important;

    th, td {
      width: auto !important; } }

  &.class3 {
    th, td {
      border-bottom: 1px solid #fff; }

    tr {
      &:last-child {
        td {
          border-bottom: none; } } } }

  &.class2 {
    th {
      border-bottom: 2px solid #fff; } } }

.block {
  &--video,
  &--expandable,
  &--default {
    &.block--color-white {
      table {
        th, td {
          border-color: $black; } } } } }
