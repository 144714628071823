.form-row {
  margin: 30px auto 0;
  max-width: 1170px;
  text-align: left;

  &.with-cols {
    .form-col {
      &.has-error {
        input[type="text"], {
          border: 1px solid #a94442;
          background-color: #F2DEDD; } } } }

  &:not(.with-cols) {
    &.has-error {
      input[type="text"],
      textarea,
      .custom-file-label {
        border: 1px solid #a94442;
        background-color: #F2DEDD; }

      .nice-checkbox,
      .nice-radio {
        label {
          color: #a94442;

          &:before {
            background-color: #F2DEDD;
            border-color: #a94442; } } }

      .select-wrapper {
        background-color: #F2DEDD;
        border: 1px solid #a94442; } } }

  & + .form-row {
    margin-top: 15px; }

  &:last-child {
    margin-top: 10px; }

  .form-row-label {
    font-size: 80%; }

  .form-row__question {
    margin-bottom: 7px; }

  .form-row-notification {
    display: block;
    padding: 5px 0 0 15px;
    //color: #a94442
    font-size: 80%; }

  .remaining-character-count {
    font-size: 80%;
    margin-top: 5px;
    display: block;

    &.hide {
      display: none; } }

  .form-col-workshop-selector, .form-col-workshop-selector-places {
    float: left; }

  .form-col-workshop-selector {
      width: calc(70% - 15px);
      margin-right: 15px; }

  .form-col-workshop-selector-places {
      width: 30%; }

  @media only screen and (min-width: 768px) {
    .form-col-firstname, .form-col-infix, .form-col-lastname {
      float: left; }

    .form-col-firstname {
      width: calc(40% - 15px);
      margin-right: 15px; }

    .form-col-infix {
      width: 20%;
      margin-right: 15px; }

    .form-col-lastname {
      width: calc(40% - 15px); } }

  @media only screen and (max-width: 767px) {
    .form-col-firstname, .form-col-infix {
      margin-bottom: 15px; } }

  label {
    @include paragraph; }

  input[type='text'],
  input[type='password'],
  textarea {
    @include paragraph;
    width: 100%;
    background: rgba(white, .8);
    border: 0;
    border-radius: 0;
    outline: 0;
    -webkit-appearance: none;
    display: block;

    &:focus {
      background-color: white; }

    .block--color-white & {
      border: 1px solid $blue; } }

  input[type='text'],
  input[type='password'] {
    // height: 40px
    padding: 10px 15px; }

  button,
  input[type='submit'] {
    margin: 0 auto;
    display: block;
    -webkit-appearance: none; }

  textarea {
    height: 150px;
    padding: 12px 15px 0; } }

.nice-checkbox,
.nice-radio {
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 15px;
  display: inline-block;

  input {

    &[type='checkbox'],
    &[type='radio'] {
      display: none;

      & + label {
        position: relative;
        padding-left: 30px;
        line-height: 22px; // hoogte van checkbox + borders
        cursor: pointer;

        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          width: 22px;
          height: 22px;
          // padding-top: 1px // omdat de border 1px groter wordt
          text-align: center;
          content: '';
          background-color: rgba(white, .8);
          border: 2px solid transparent;

          .block--color-white & {
            border: 2px solid $blue; } } }

      &:hover,
      &::before {
        padding-top: 0;
        border-width: 2px; }

      &:checked + label::before {
        color: white;
        background-color: $blue !important;
        border-color: $white; } }

    &[type='checkbox'] + label::before {
      font-family: FontAwesome;
      font-size: 14px;
      line-height: 18px;
      color: transparent;
      content: '\f00c'; }

    &[type='radio'] + label::before {
      border-radius: 50%; } } }

.nice-radio {
  margin-bottom: 10px; }

.block--color-white {
  .nice-checkbox,
  .nice-radio {
    input {
      &[type='checkbox'],
      &[type='radio'] {
        &:checked + label::before {
          border-color: $blue; } } } } }

.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  //max-width: 150px
  height: 40px;
  font-size: 16px;
  overflow: hidden;
  background: rgba(white, .8); }

.select-wrapper__select {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-content + 4;
  width: 100%;
  height: 40px;
  //color: transparent
  color: $black;
  background-color: transparent;
  background-image: none;
  border: 0;
  outline: none;
  box-shadow: none;
  opacity: 0;
  appearance: none; }

.select-wrapper__cover {
  position: relative;
  z-index: $z-index-content + 1;
  display: block;
  // padding: 12px 15px 0
  padding: 6px 15px;
  min-height: 40px;
  color: $black;
  pointer-events: none;
  border: 0;

  &::after {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: $z-index-content + 2;
    font-family: FontAwesome;
    color: $black;
    pointer-events: none;
    content: ''; }

  .block--color-white & {
    padding-top: 9px;
    padding-bottom: 3px;
    border: 1px solid $blue;

    &::after {
      top: 8px; } } }

// Custom file input.
$custom-file-height: 40px;

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  opacity: 0; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: 10px 15px;
  background: rgba(white, .8);
  color: $black;
  line-height: 22px !important;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    // height: $custom-file-height
    background-color: $blue;
    padding: 10px 15px;
    content: "Browse";
    // font-family: verdana, arial, sans-serif
    font-size: 14px;
    color: #fff; } }

body.lang-nl {
  .custom-file-label {
    &::after {
      content: "Bladeren"; } } }

