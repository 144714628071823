// -----------------------
// AVAILABLE MODIFIERS
// - .block--color-default
// - .block--color-green
// - .block--color-blue
// - .block--color-white
// -----------------------

.block {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &--video,
  &--expandable,
  &--default {

    &.block--color-orange {
      background-color: $orange;

      .block__inner {
        background-color: $orange;
        background-image: none; } }

    &.block--color-red {
      background-color: $red;

      .block__inner {
        background-color: $red;
        background-image: none; } }

    &.block--color-blue {
      background-color: $blue;

      .block__inner {
        background-color: $blue;
        background-image: none; } }

    &.block--color-yellow {
      background-color: $yellow;

      .block__inner {
        background-color: $yellow;
        background-image: none;
        color: $blue; } }

    &.block--color-white {
      background-color: white;
      color: black;

      .registration-form-text {
        border-color: $blue; }

      .block__inner {
        background-color: white;
        background-image: none; }

      h2 {
        color: $blue; }

      .button--secondary {
        color: white;
        background-color: $blue; } } }

  &--with-map {
    .google-map {
      margin: -30px;

      &-height-small {
        height: 300px; }

      &-height-medium {
        height: 400px; }

      &-height-large {
        height: 500px; } }

    p:last-child {
        padding-bottom: 30px; } }

  &--expandable {
    .google-map {
      margin: 0; }

    // &.block--with-signup-form
    //   &.is-open
    //     .block-title, .block-subtitle
 }    //       display: none

  .block-notification {
    margin-bottom: 15px; }

  .registration-form-text {
    margin: 15px auto 0 auto;
    max-width: 700px;
    border: 1px solid #fff;
    padding: 15px; } }
