.text-holder {
  //+heading-3

  h2 {
    @include heading-2-normal; }

  table {
    margin-top: 20px; }

  p {
    margin-top: 20px;
    font-weight: normal;

    a {
      &:not(.button) {
        text-decoration: underline;
        // display: inline-block
        // height: 18px
        // padding-bottom: 0
        // border-bottom: 1px solid currentColor

        &:hover {
          text-decoration: none;
 } } } }          // border-bottom: 0

  .contact-form-success-message {
    font-size: 24px;

    p {
      font-weight: bold; } }

  .button {
    margin-top: 20px;
    margin-bottom: 10px; } }
