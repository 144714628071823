// ----------------------------
// AVAILABLE MODIFIERS
// - .block--intro--align-left
// - .block--intro--align-right
// ----------------------------

.block--intro {
  min-height: 100vh; //calc(100vh - 70px)

  .block-intro-logo {
    position: absolute;
    z-index: 102;
    width: 360px;
    padding: 30px 50px;

    @media only screen and (max-width: 480px) {
      //width: 100%
      width: 150px;
      padding: 15px; }

    @media only screen and (min-width: 768px) and (max-height: 750px) {
      width: 260px; } }


  &.has-signup {
    .block__inner {
      bottom: 130px;

      @media only screen and (max-width: 767px) {
        bottom: 105px; } } }

  &--align-left {

    .block__inner {
      left: 50px; } }

  &--align-right {

    .block__inner {
      right: 50px; } }

  &:not(.block--intro--hide-bubbles) {

    &::before,
    &::after {
      @include stretch;
      position: absolute;
      z-index: $z-index-content;
      display: block;
      pointer-events: none;
      content: '';
      background-repeat: no-repeat; }

    &::before {
      background-image: url('/assets/website/img/block/intro/glow-green.png');
      background-position: left;
      background-size: auto 100%; }

    &::after {
      background-image: url('/assets/website/img/block/intro/glow-blue.png');
      background-position: bottom right; } }

  .block__inner {
    position: absolute;
    bottom: 15px;
    z-index: $z-index-content + 1; }

  .block__options {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-content + 1;
    height: 90px;
    padding-top: 28px;
    text-align: center;
    // background: linear-gradient(to right, rgba($ok-blue, .75) , rgba($ok-green, .75))
 } }    // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#73b9eb', endColorstr='#009900', GradientType=1)

.scroll-indicator {
  position: absolute;
  bottom: 110px;
  left: 50%;
  z-index: $z-index-content + 1;
  margin-left: -12px;
  display: block;
  width: 24px;
  height: 40px;
  border: 2px solid #ffffff;
  border-radius: 16px;
  outline: 0;

  &__wheel {
    position: relative;
    top: 4px;
    margin: auto;
    width: 2px;
    height: 6px;
    background: #fff;
    border-radius: 10px;
    transition: background-color 0.25s; } }

.block__carousel {
  z-index: $z-index-below-content;

  // &::after
  //   +stretch
  //   position: absolute
  //   z-index: $z-index-content
  //   content: ''
  //   background: rgba(black, .2)

  &__item {
    min-height: 100vh; //calc(100vh - 70px)
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover; } }

body.production {
  .scroll-indicator {
    &__wheel {
      animation-name: drop;
      animation-duration: 1.3s;
      animation-play-state: running;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite; } } }

@keyframes drop {

  0% {
    top: 5px;
    opacity: 0; }

  30% {
    top: 10px;
    opacity: 1; }

  100% {
    top: 25px;
    opacity: 0; } }

@media only screen and (max-width: 920px) {

  .scroll-indicator {
    display: none; } }

@media only screen and (max-width: 767px) {

  .block--intro {

    &:not(.block--intro--hide-bubbles) {

      &::before {
        background: rgba(black, .2); }

      &::after {
        display: none; } } }

  // .block--intro .block__inner
  //   bottom: (90px + 15px)

  .block--intro {

    &--align-left {

      .block__inner {
        left: 15px; } }

    &--align-right {

      .block__inner {
        right: 15px; } } } }
