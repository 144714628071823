.gallery--grid {
  @include clearfix;

  .block--default & {
    margin: 25px 20px;
    margin-bottom: 0; }

  .block--expandable & {
    margin: 0 0 25px; }

  .gallery__item,
  .gallery__item__image {

    &::before {
      display: block;
      width: 100%;
      padding-top: (180 / 250) * 100%;
      content: ''; } }

  .gallery__item {
    position: relative;
    float: left;
    margin-top: 25px;
    width: calc((100% - (25px * 4)) / 5);
    outline: 0;

    &:not(:nth-child(5n-4)) {
      margin-left: 25px; } }

  .gallery__item__image {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    background-size: cover; }

  .gallery__item__information {

    h3 {
      @include paragraph;
      margin-top: 1em;
      font-weight: bold; }

    p {
      @include paragraph;
      margin-top: 0; } } }

.download-btn-wrap {
  text-align: left;
  margin-bottom: 25px;

  @media only screen and (min-width: 768px) {
    margin-left: 20px; }

  .button {
    padding-top: 7px; } }

@media only screen and (min-width: 921px) {

  .block--expandable {

    .gallery--grid {

      .gallery__item {
        width: calc((100% - (25px * 2)) / 3);

        &:nth-child(n) {
          margin-left: 0; }

        &:not(:nth-child(3n-2)) {
          margin-left: 25px; } } } } }

@media only screen and (max-width: 1140px) {

  .gallery--grid {

    .gallery__item {
      width: calc((100% - (25px * 3)) / 4);

      &:nth-child(n) {
        margin-left: 0; }

      &:not(:nth-child(4n-3)) {
        margin-left: 25px; } } } }

@media only screen and (max-width: 920px) {

  .gallery--grid {

    .gallery__item {
      width: calc((100% - (25px * 2)) / 3);

      &:nth-child(n) {
        margin-left: 0; }

      &:not(:nth-child(3n-2)) {
        margin-left: 25px; } } } }

@media only screen and (max-width: 767px) {

  .block--default,
  .block--expandable {

    .gallery--grid {
      margin: 0; } }

  .gallery--grid {

    .gallery__item {
      margin-top: 15px;
      width: calc((100% - (15px * 2)) / 3);

      &:not(:nth-child(3n-2)) {
        margin-left: 15px; } } } }

@media only screen and (max-width: 530px) {

  .gallery--grid {

    .gallery__item {
      width: calc((100% - (15px * 1)) / 2);

      &:nth-child(n) {
        margin-left: 0; }

      &:not(:nth-child(2n-1)) {
        margin-left: 15px; } } } }
