.modaal-gallery-control {
  position: absolute;
  top: 50%;
  margin-top: -22px;
  width: 44px;
  height: 44px;
  padding: 0 2px;
  font-size: 40px;
  text-align: center;
  color: $blue;
  cursor: pointer;
  background: white;
  border: 1px solid currentColor;
  outline: 0;

  &.is_hidden {
    cursor: default;
    opacity: 0; }

  span {
    display: none; }

  &::after {
    font-family: FontAwesome; } }

.modaal-gallery-prev {
  right: 100%;
  margin-right: 30px;

  &::after {
    content: $fa-var-angle-left; } }

.modaal-gallery-next {
  @media only screen and (min-width: 1041px) {
    margin-left: 30px;
    left: 100%; }

  &::after {
    content: $fa-var-angle-right; } }

.modaal-close {
  position: fixed;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  outline: 0;

  &::after {
    font-family: FontAwesome;
    content: $fa-var-times-circle; }

  span {
    display: none; }

  .modaal-fullscreen & {
    top: 10px;
    right: 10px; } }

// standaard wordt ie blijkbaar verborgen als je de hoogte kleiner zet
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: block; } }
