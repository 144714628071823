.gallery--inline {
  @include clearfix;
  top: -30px;
  left: -30px;
  margin-bottom: -60px;
  width: calc(100% + 60px);

  .gallery__item {
    position: relative;
    float: left;
    width: calc(100% / 3);
    outline: 0;

    &::before {
      display: block;
      width: 100%;
      padding-top: (400 / 480) * 100%;
      content: ''; } }

  .gallery__item__image,
  .gallery__item__information {
    @include stretch;
    position: absolute;
    display: block; }

  .gallery__item__image {
    background-size: cover; }

  .gallery__item__information {
    padding: 50px;
    text-align: center;
    color: white;
    background: rgba($blue, .5);
    opacity: 0;
    transition: opacity $transition-speed $transition-easing;

    .block--color-green & {
 }      // background: rgba($ok-green, .5)

    h3 {
      @include heading-2-bold; }

    p {
      @include paragraph; } } }

.gallery--inline .gallery__item:hover .gallery__item__information,
html.touchevents .gallery--inline .gallery__item__information {
  opacity: 1; }

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: $z-index-above-content;
  margin-top: -22px;
  display: block;
  width: 44px;
  height: 44px;
  padding: 0;
  padding-left: 2px;
  font-size: 40px;
  color: white;
  cursor: pointer;
  background: transparent;
  border: 1px solid currentColor;
  outline: 0; }

.slick-prev {
  left: 15px; }

.slick-next {
  right: 15px; }

@media only screen and (max-width: 768px) {

  .gallery--inline {
    display: flex;
    flex-direction: column;

    .gallery__item {
      width: 100%; }

    .gallery__item__information {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center; } } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {

  .gallery--inline {

    .gallery__item::before {
      padding-top: (3 / 4) * 100%; }

    .gallery__item__information {
      justify-content: flex-end; } } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {

  .gallery--inline.slick-slider {

    .gallery__item__information {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 70px; } } }

@media only screen and (max-width: 767px) {

  .gallery--inline {
    top: -30px;
    left: -15px;
    width: calc(100% + 30px);

    .gallery__item__information {
      padding: 30px; } } }
