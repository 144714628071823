.footer {
  height: 70px;
  padding: 0 30px;
  background-color: $white;

  .logo {
    background-image: url('/assets/website/img/footer/logo.png');
    float: right; }

  p {
  	line-height: 20px;
  	padding: 25px 0;
  	float: left; } }

@media only screen and (max-width: 767px) {

  .footer {
    padding-right: 15px;
    padding-left: 15px; } }
