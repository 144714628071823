.title-holder {

  h1 {
    @include heading-1-bold;

    // @media only screen and (min-width: 768px) and (max-height: 750px)
    @media only screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 34px; } }

  h2 {
    @include heading-1-normal;
    margin-top: -0.152em; // -10px

    // @media only screen and (min-width: 768px) and (max-height: 750px)
    @media only screen and (max-width: 767px) {
      font-size: 30px;
      line-height: 34px; } }

  h3 {
    font-weight: normal;
    font-size: 38px;
    line-height: 1;
    margin-top: -0.152em; // -10px

    @media only screen and (min-width: 768px) and (max-height: 750px) {
      font-size: 24px;
      line-height: 28px; } }

  p {
    @include heading-2-bold;
    margin-top: 0.227em; // 10px

    .sub-subtitle {
      font-size: 56px;
      line-height: 1;
      font-weight: normal;

      @media only screen and (min-width: 768px) and (max-height: 750px) {
        font-size: 22px;
        line-height: 26px; } }

    @media only screen and (min-width: 768px) and (max-height: 750px) {
      font-size: 20px;
      line-height: 24px; } }

  h2 + p {
    position: relative;
    padding-top: 0.795em; // 35px

    &::before {
      position: absolute;
      top: 0;
      display: block;
      width: 120px;
      height: 3px;
      content: '';
      background: white;

      .block--color-white & {
        background: black; } } } }

@media only screen and (max-width: 767px) {

  .title-holder {

    &:first-child {
      margin-top: 0; }

    h1 {
      @include heading-2-bold; }

    h2 {
      @include heading-2-normal; }

    p {
      @include heading-3;
      font-weight: bold;

      .sub-subtitle {
        font-size: 24px;
        line-height: 1;
        font-weight: normal; } } } }
