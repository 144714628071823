// ---------------------------------
// AVAILABLE MODIFIERS
// - .block--expandable--align-left
// - .block--expandable--align-right
// ---------------------------------

.block--expandable {
  @include clearfix;

  &--align-left {

    .block__inner {
      float: left; } }

  &--align-right {

    .block__inner {
      float: right; } }

  .block__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    min-height: 400px;
    padding: 30px;
    background-size: cover;
    transition-timing-function: $transition-easing;
    transition-duration: $transition-speed * 2;
    transition-property: width;

    .text-holder {

      p {
        max-width: 800px; } } }

  .block__toggle {
    margin-top: 15px;
    display: block;
    width: 44px;
    height: 44px;
    padding-left: 2px;
    font-size: 40px;
    text-align: center;
    color: $blue;
    background: white;
    border: 1px solid currentColor;
    outline: 0;
    transition-timing-function: $transition-easing;
    transition-duration: $transition-speed;
    transition-property: transform, background, color; }

  &.block--color-red {
    .block__toggle {
      color: $red; } }

  &.block--color-yellow {
    .block__toggle {
      color: $blue; }

    &.is-open {
      .block__toggle {
        color: $blue; } } }

  &.block--color-white {
    &.is-open {
      .block__toggle {
        color: $blue; } } }

  .block__additional-content {
    display: none;
    width: calc(1080px - 100px);
    max-width: calc(100vw - 100px);
    opacity: 0;
    transition-timing-function: $transition-easing;
    transition-duration: $transition-speed * 2;
    transition-property: opacity;

    &.is-visible {
      opacity: 1; } }

  &.is-open {

    .block__inner {
      width: 1080px;
      max-width: 100%; }

    .block__toggle {
      color: white;
      background: transparent;
      transform: rotate(-90deg); } }

  &:not(.is-open) .block__inner {
    &.clickable {
      cursor: pointer; } }

  .form-row {
    button,
    input[type='submit'] {
      margin-left: 0;
      margin-right: 0; } } }

// body
//   &.company-hg
//     .block--expandable
//       &.block--color-default
//         .block__inner
//           background: url('/assets/website/img/block/expandable/block-inner-background-hg.png')
//   &.company-hmc
//     .block--expandable
//       &.block--color-default
//         .block__inner
//           background: url('/assets/website/img/block/expandable/block-inner-background-hmc.png')
//   &.company-hfg
//     .block--expandable
//       &.block--color-default
//         .block__inner
//           background: url('/assets/website/img/block/expandable/block-inner-background-hfg.png')

@media only screen and (max-width: 767px) {

  .block--expandable .block__inner {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }

  .block--expandable .block__additional-content {
    max-width: calc(100vw - 30px); } }
