.logo {
  float: left;
  @include hide-text;
  width: 78px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 100%;
  margin-top: 15px; }

body {
	&.company-hg {
		.logo {
			background-image: url('/assets/website/img/header/hg.svg'); } }

	&.company-hmc {
		.logo {
			background-image: url('/assets/website/img/header/hmc.svg'); } }

	&.company-hfg {
		.logo {
			background-image: url('/assets/website/img/header/hfg.svg'); } } }
