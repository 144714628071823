// ----------------------------
// AVAILABLE MODIFIERS
// - .block--video--align-left
// - .block--video--align-right
// ----------------------------

.block--video {
  min-height: 400px;

  &--align-left {

    .block__inner {
      left: 0; }

    .block__button {
      right: calc(50% - 180px);
      margin-right: -60px; } }

  &--align-right {

    .block__inner {
      right: 0; }

    .block__button {
      left: calc(50% - 180px);
      margin-left: -60px; } }

  &--align-center {
    .block__button {
      left: 50%;
      margin-left: -60px; } }

  .block__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 360px;
    padding: 30px;
    background: url('/assets/website/img/block/video/block-inner-background.png');
    background-size: cover; }

  .block__button {
    position: absolute;
    top: 50%;
    margin-top: -60px;
    display: block;
    width: 120px;
    height: 120px;
    border: 6px solid white;
    border-radius: 120px;
    outline: none;

    &:focus,
    &:hover {
      opacity: .8; }

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -19px;
      margin-left: -10px;
      width: 0;
      height: 0;
      content: '';
      border-color: transparent transparent transparent #ffffff;
      border-style: solid;
      border-width: 19px 0 19px 30px; } } }

@media only screen and (max-width: 767px) {

  .block--video {

    &--align-left {

      .block__button {
        right: 50%; } }

    &--align-right {

      .block__button {
        left: 50%; } }

    .block__inner {
      width: 100%;
      padding: 15px;
      background: rgba(black, .2); } } }

@media only screen and (max-width: 530px) {

  .block--video {
    min-height: calc(100vw / 4 * 3);

    &--align-left,
    &--align-right {

      .block__button {
        top: initial;
        right: 20px;
        bottom: 20px;
        left: initial;
        margin: 0;
        transform: scale(.5);
        transform-origin: bottom right; } } } }
