// @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

// @font-face {
//   font-family: 'Agilita';
//   font-style: normal;
//   font-weight: 700;
//   src: url('/assets/website/fonts/agilita-700.woff2') format('woff2'),
//        url('/assets/website/fonts/agilita-700.woff') format('woff');
// }

// @font-face {
//   font-family: 'Agilita';
//   font-style: normal;
//   font-weight: 900;
//   src: url('/assets/website/fonts/agilita-900.woff2') format('woff2'),
//        url('/assets/website/fonts/agilita-900.woff') format('woff');
// }

$font-default: 'Raleway', sans-serif;
$font-heading: 'Raleway', sans-serif;
$verdana: Verdana , Arial, sans-serif;