$z-index-above-content: 200;
$z-index-content: 100;
$z-index-below-content: 0;

$transition-speed: .2s;
$transition-easing: ease-out;

$black: #5d5c5c;
$white: #ffffff;

$orange: #ba9760; // goud
$red: #292929; // zwart
$yellow: #fd0;
$blue: #292929;

$fa-font-path: "/assets/website/fonts";
