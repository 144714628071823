.block--default {
  padding: 30px;

  &.block--color-default {

    // &::before
    //   +stretch
    //   position: absolute
    //   z-index: $z-index-content
    //   display: block
    //   pointer-events: none
    //   content: ''
 }    //   background: rgba(black, .45)

  .title-holder,
  .text-holder,
  .gallery {
    position: relative;
    z-index: $z-index-content + 1; }

  .title-holder {
    margin: 15px 20px 0; }

  .text-holder {
    text-align: center;
    //max-width: 1170px
    //margin: 0 auto
    p, table {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto; } } }

@media only screen and (max-width: 767px) {

  .block--default {
    padding-right: 15px;
    padding-left: 15px; }

  .block--default {

    .title-holder {
      margin-right: 0;
      margin-left: 0; } } }
