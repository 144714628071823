body {
 }  // border-top: 70px solid white

.header {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-above-content;
  height: 130px;
  padding: 0 50px;
 }  // background: white

.header__button {
  float: right;
  margin-top: 15px; }

.top-notification {
  position: fixed;
  z-index: $z-index-above-content + 1;
  top: 23px;
  right: 0;
  left: 0;
  text-align: center;
  //font-size: 20px
  color: $black; }

.dropdown {
  float: right;
  margin-top: 15px;
  margin-left: 15px;

  > a {
    display: inline-block;

    img {
      width: 20px;
      height: auto;
      display: block; } }

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    position: absolute;
    right: 50px;
    background-color: #fff;
    padding: 5px 10px;
    padding-top: 7px;
    border: 1px solid $black;

    > li {
      margin-top: 3px;

      > a {
        color: $black;
        padding: 3px;
        display: block;

        img {
          width: 20px;
          height: auto;
          display: inline-block;
          vertical-align: middle;
          margin-right: 3px;
          margin-bottom: 2px; } } } }

  &.active {
    > ul {
      display: block; } } }

@media only screen and (max-width: 767px) {

  .header {
    padding-right: 15px;
    padding-left: 15px; } }
