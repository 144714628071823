@mixin clearfix {
  overflow: hidden; }

@mixin legacy-clearfix {

  &::after {
    clear: both;
    display: table;
    content: ''; } }

@mixin stretch {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

@mixin hide-text {
  text-indent: -9999px; }

@mixin remove-list-styles {
  margin: 0;
  padding: 0;
  list-style: none; }
